import { render, staticRenderFns } from "./keep_on_record.vue?vue&type=template&id=89e7a058&scoped=true&"
import script from "./keep_on_record.vue?vue&type=script&lang=ts&"
export * from "./keep_on_record.vue?vue&type=script&lang=ts&"
import style0 from "./keep_on_record.vue?vue&type=style&index=0&id=89e7a058&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89e7a058",
  null
  
)

export default component.exports